import React, { useEffect, useState, useRef } from "react"
import styled from "@emotion/styled"
import { useIntl } from "gatsby-plugin-intl"
import { useWindowSize } from "helpers/hooks"
import Layout from "components/layout"
import SEO from "components/seo"
import E from "components/experiences"
import { mqTablet } from "static/constants"
import { config, useSpring } from "react-spring"

const CurriculumVitae = () => {
  const intl = useIntl()
  const expRef = useRef(null)
  const { height } = useWindowSize()
  const [elementHeight, setElementHeight] = useState(`auto`)
  const [headerHeight, setHeaderHeight] = useState(0)
  const [isClosed, setIsClosed] = useState(false)
  const [isMinimized, setIsMinimized] = useState(false)

  useEffect(() => {
    setHeaderHeight(expRef.current.firstElementChild.clientHeight)
  }, [expRef])

  function handleMinimize(didMinimize) {
    const newHeight = didMinimize ? headerHeight : `auto`
    setIsMinimized(didMinimize)
    setElementHeight(newHeight)
  }

  const minimizeProps = useSpring({
    config: isMinimized
      ? { mass: 1, tension: 500, friction: 0, clamp: true }
      : config.stiff,
    position: isMinimized ? `fixed` : `static`,
    top: isMinimized ? height - headerHeight : 0,
    right: isMinimized ? `2w` : `25vw`,
    maxWidth: isMinimized ? `300px` : `700px`,
    transform: isClosed ? `scaleX(0) scaleY(0)` : `scaleX(1) scaleY(1)`,
  })

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: `page.cv` })} />
      <Wrapper>
        <Experiences
          setIsClosed={setIsClosed}
          setIsMinimized={handleMinimize}
          style={minimizeProps}
          height={elementHeight}
          ref={expRef}
        />
      </Wrapper>
    </Layout>
  )
}

export default CurriculumVitae

const Experiences = styled(E)(({ height }) => ({
  margin: `auto`,
  width: `90vw`,
  height: height || `auto`,
  transition: `height 0.2s ease`,
  zIndex: 0,
  [mqTablet]: {
    boxShadow: `none`,
    border: `none`,
  },
}))

const Wrapper = styled.div({
  margin: `0 auto`,
  padding: `150px 0`,
  [mqTablet]: {
    margin: `20px auto 80px`,
    padding: 0,
  },
})
